.DiffContentView .added {
  color: #32cd32;
}

.DiffContentView .removed {
  color: red;
  text-decoration: line-through;
}

.settings-form-content {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.tag-loader {
  max-width: 450px;
  padding: 0 0 30px;
}

.tag-drawer {
  cursor: pointer;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  padding: 20px 15px;
}

.tag-drawer.loaded {
  border-color: #388e3c;
}

.tag-drawer.error {
  border-color: var(--danger-color);
}

.tag-loader .hidden {
  opacity: 0;
  height: 0;
  margin: 0;
}

.stories-view {
  margin-top: 30px;
}

.stories-view article {
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.process-loader {
  background-color: #3c3c3c1a;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
}

.multithread-process-tracker {
  margin-top: 20px;
}

.multithread-loader {
  background-color: #9696961a;
  margin-bottom: 20px;
  padding: 20px;
  overflow: auto;
}

.multithread-loader .story-loader {
  float: left;
  cursor: pointer;
  border: 1px solid #64646433;
  border-radius: 6px;
  max-width: 250px;
  margin-right: 10px;
  padding: 15px;
}

.multithread-loader .story-loader.selected {
  border-bottom: 5px solid #1095c1;
  box-shadow: .0145rem .029rem .174rem #00000004, .0335rem .067rem .402rem #00000006, .0625rem .125rem .75rem #00000008, .1125rem .225rem 1.35rem #00000009, .2085rem .417rem 2.502rem #0000000b, .5rem 1rem 6rem #0000000f, 0 0 0 .0625rem #00000004;
}

.process-log {
  background-color: #9696961a;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  display: block;
  overflow: auto;
}

.process-log p.episode-log-title {
  margin-top: 10px;
}

.process-log p {
  margin: 0;
  font-size: .7em;
}

.process-log p.danger {
  color: #f1c40f;
}

:root {
  --danger-color: crimson;
}

.text-right {
  text-align: right;
}

h1.title, h2.title, h3.title {
  margin-bottom: 0;
}

.very-small {
  font-size: .7em;
}

.sample-select {
  text-align: center;
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 0;
}

.sample-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.process-label {
  margin-bottom: 15px;
  line-height: 1.3;
  display: block;
}

.protected-paragraph {
  opacity: .7;
  background-color: #ffffff1a;
  border-radius: 3px;
  padding-left: 5px;
}

.model-indicator {
  border-bottom: 1px solid #edf0f3;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.audio-tags {
  color: #fff;
  float: right;
  background-color: #f0f;
  border-radius: 3px;
  padding: 0 5px;
  font-family: sans-serif;
  font-size: .55em;
  display: block;
}

.danger {
  color: var(--danger-color);
}

.mtba20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.flex-cont, .block-cont {
  display: flex;
}

/*# sourceMappingURL=index.51356c14.css.map */
