:root{
  --danger-color: crimson;
}

.text-right{
  text-align: right;
}

h1.title, h2.title, h3.title{
  margin-bottom: 0px;
}

.very-small{
  font-size: 0.7em;
}

.sample-select{
  margin-bottom: 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
  text-align: center;
}

.sample-button{
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.process-label{
  line-height: 1.3;
  margin-bottom: 15px;
  display: block;
}

.protected-paragraph{
  background-color: rgba(255,255,255,0.1);
  padding-left: 5px;
  border-radius: 3px;
  opacity: 0.7;
}

.model-indicator{
  padding-bottom: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid hsl(205, 20%, 94%);
}

.audio-tags{
  background-color: magenta;
  color: white;
  border-radius: 3px;
  display: block;
  float: right;
  padding: 0px 5px;
  font-size: 0.55em;
  font-family: sans-serif;
}

.danger{
  color: var(--danger-color);
}

.mtba20{
  margin-top: 20px;
  margin-bottom: 20px;
}

.flex-cont{
  display: flex;
}

.block-cont{
  display: flex;
}
