.tag-loader{
  padding: 0px 0px 30px 0px;
  max-width: 450px;
}

.tag-drawer{
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 20px 15px 20px 15px;
  cursor: pointer;
}

.tag-drawer.loaded{
  border-color: #388e3c;
}

.tag-drawer.error{
  border-color: var(--danger-color);
}

.tag-loader .hidden{
  opacity: 0;
  height: 0px;
  margin: 0px;
}
